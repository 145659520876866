import { Component } from '@angular/core';
import { template } from '@angular/core/src/render3';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  public company: any = {
    name:'Virtual card'
  }
}
