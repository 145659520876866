import { Component, OnInit } from '@angular/core';
import { Payment } from './payment';
import { ActivatedRoute } from '@angular/router'
import {HttpClient} from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";

declare let window: any;
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  isLoading = false;
  payment: Payment = new Payment();
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    const self = this;
    self.loadDataPayment();
    let forms = document.getElementsByClassName('needs-validation');
    let validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          self.submitHandler();
          self.yourComponentClass();
        }
        form.classList.add('was-validated');
      }, false);
    });
    console.log("validationform: ", validation);
  }

  loadDataPayment() {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      const data = params['data'];
      console.log("id: ", id);
      console.log("data: ", data);
    });
  }

  submitHandler() {
    this.isLoading = true;
    console.log("payment: ", this.payment);
    const url = 'https://payment.oregonback.snbxt.com/api/v/3.0/service/broker/invoice/create/';
    let token = 'V33ntOZLQGKH8Nba40ORU5glQlxWqh';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      })
    };
    const firtsname = this.payment.name.split(" ")[0];
    const lastname = this.payment.name.split(" ")[1];
    const phonenumber = this.payment.areacode + this.payment.number;
    if (this.payment.fullname == null) {
        this.payment.fullname = this.payment.name;
        this.payment.emailGift = this.payment.email;
    };
    const params = {
      'merchant_key': 'd2222558-bbbe-11ee-a157-9d7297eb2329',
      'type': '5',
      'first_name': firtsname,
      'last_name': lastname,
      'full_name': this.payment.name,
      'country': this.payment.country,
      'email': this.payment.email,
      'phone': phonenumber,
      'amount': this.payment.amount,
      'response_url': 'https://wallet.caribmall.com/#/accepted',
      'comments': this.payment.email,
      'gift_full_name': this.payment.fullname,
      'gift_email': this.payment.emailGift,
    };
    this.http.post(url, params, httpOptions).subscribe(
      (response: any) => {
        this.isLoading = false;
        console.log(response);
        window.location.href = response.data;
      },
      error => {
        console.error('Error:', error);
        this.isLoading = false;
      }
    );
  }

  yourComponentClass () {
    this.payment.isGift = false;
  }
}


