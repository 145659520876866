import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-succeed',
  templateUrl: './succeed.component.html',
  styleUrls: ['./succeed.component.css']
})
export class SucceedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
