import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PaymentsComponent } from './payments/payments.component'

import { RouterModule, Routes, Router } from '@angular/router'
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ConfirmCodeComponent } from './confirm-code/confirm-code.component';
import { SucceedComponent} from './succeed/succeed.component'

const routes: Routes = [
  { path: '', redirectTo: '/card', pathMatch: 'full' },
  { path: 'card', component: PaymentsComponent },
  { path: 'payment/:id/:data', component: PaymentsComponent },
  { path: 'confirmCode', component: ConfirmCodeComponent },
  { path: 'success', component: SucceedComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PaymentsComponent,
    ConfirmCodeComponent,
    SucceedComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
