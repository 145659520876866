export class Payment {
  name: string;
  email: string;
  country: string;
  amount: number;
  areacode: number;
  number: number;
  carbmallID: number;
  isGift: boolean;
  isLoading: boolean;
  emailGift: string;
  fullname: string;
}
